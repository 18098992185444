body,
html,
#root {
    font-family: Poppins-Regular;
    font-size: 10px;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: none;
    position: fixed;
    overflow: hidden;
    background-color: #f2f2f0;
}

pre {
    font-family: Poppins-Regular;
    white-space: break-spaces;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url(../fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url(../fonts/Poppins-Regular.ttf) format("truetype");
}

.main-list-scroll {
    height: calc(100vh - 170px) !important;
    overflow: auto !important;
    width: 100%;
}

.only-print {
    display: none;
}

@media print {
    body,
    html,
    #root {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
        position: static;
        overflow: auto;
    }
    .no-print {
        display: none;
    }

    .only-print {
        display: block;
    }

    * {
        overflow-y: visible;
    }
}

table {
    position: relative;
    border-collapse: collapse;
    margin-bottom: 30px;
    overflow: hidden;
}
table tfoot {
    display: table-footer-group;
}
#print-area {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    padding: auto;
}

th,
td {
    page-break-inside: avoid;
    text-align: left;
    padding: 12px 15px;
    vertical-align: top;
    border: 0px solid black;
}
tr {
    border-top: 1px solid lightgray;
    vertical-align: top;
}
thead {
    display: table-header-group;
}

tfoot {
    display: table-footer-group;
}

@page {
    size: ledger landscape;
    margin: 15mm 15mm 15mm 15mm;
}

.fullPage {
    height: 100vh;
}
